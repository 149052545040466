import React from 'react'
import PlacesAutocomplete from '../AutoCompleteAddress'
import { FormSpy } from 'react-final-form'
import InputField from './Fields/InputField'

const translationsKeys = {
  addressLine1Label: 'addressLine1Label',
}

const GoogleMapsScript = ({
  country,
  addressFieldName,
  dataType,
  translations,
  address,
  setAddress,
  handleAddressSelect
}) => {
  return (
    <PlacesAutocomplete
      onChange={(value) => {
        return setAddress(value)
      }}
      value={address}
      onSelect={handleAddressSelect}
      searchOptions={{
        componentRestrictions: { country: [`${country.countryCode}`] }
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <FormSpy>
          {(props) => (
            <>
              <InputField
                label={translations[translationsKeys.addressLine1Label]}
                name={addressFieldName}
                {...{ [dataType]: 'address1' }}
                placeholder={translations[translationsKeys.addressLine1Label]}
                required
                {...getInputProps()}
              />

              <div className="absolute z-10 w-full autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {address &&
                  props.active === addressFieldName &&
                  suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? 'suggestion-item--active bg-white p-2'
                      : 'suggestion-item bg-primary__gp-100 p-2'
                    // inline style for demonstration purpose
                    return (
                      <div
                        key={suggestion.placeId}
                        {...getSuggestionItemProps(suggestion, {
                          className
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    )
                  })}
              </div>
            </>
          )}
        </FormSpy>
      )}
    </PlacesAutocomplete>
  )
}

export default GoogleMapsScript
