import IsEmail from 'validator/lib/isEmail'
import { phone } from 'phone'

const translationKeys = {
  requiredError: 'requiredError',
  emailError: 'emailError',
  phoneError: 'phoneError',
  zipcodeError: 'zipcodeError'
}

const userValidation = (values = {}, translations) => {
  const errors = {
    customer: {}
  }

  const { customer } = values

  if (customer) {
    const { email, phoneNumber, dateOfBirth } = values.customer

    if (email && !IsEmail(email)) {
      errors.customer.email = translations[translationKeys.emailError]
    }

    if (!phoneNumber) {
      errors.customer.phoneNumber = translations[translationKeys.requiredError]
    }

    if (phoneNumber) {
      // Don't want to have valid numbers marked as invalid due to the library being out of date with mobile prefixes.
      // As long as the format is valid let's consider it valid so will turn validateMobilePrefix off.
      const { isValid } = phone(phoneNumber, { validateMobilePrefix: false })
      if (!isValid) {
        errors.customer.phoneNumber = translations[translationKeys.phoneError]
      }
    }

    if (!dateOfBirth) {
      errors.customer.dateOfBirth = translations[translationKeys.requiredError]
    }
  }

  return errors
}

const requireFields = (value, errors, translations) => {
  const fields = [
    'first_name',
    'last_name',
    'country',
    'line_1',
    'city',
    'state',
    'postcode'
  ]
  for (const item in fields) {
    // check if there is province or state field but not in the correct country
    if (
      (fields[item] === 'state' && value.country !== 'US') ||
      (fields[item] === 'province' && value.country !== 'CA')
    ) {
      continue
    }
    validateRequired(value, fields[item], errors, translations)
  }
}

const validateRequired = (value, item, errors, translations) => {
  if (!value || !value[item]) {
    errors[item] = translations[translationKeys.requiredError]
  }
}

const validateFields = (value, errors, translations) => {
  const zipcodeFields = ['postcode']

  for (const zf in zipcodeFields) {
    value &&
      !errors[zipcodeFields[zf]] &&
      validateZipcode(value, zipcodeFields[zf], errors, translations)
  }
}

const validateZipcode = (value, item, errors, translations) => {
  const zip_pattern =
    value.country === 'US'
      ? /^[0-9]{5}(?:-[0-9]{4})?$/
      : value.country === 'CA'
        ? /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/
        : /^[A-Za-z0-9\-\s]*$/
  if (!zip_pattern.test(value[item] || '')) {
    errors[item] = translations[translationKeys.zipcodeError]
  }
}

const AddressFieldsValidation = (values, translations) => {
  const errors = {
    shipping_address: {}
  }

  requireFields(values.shipping_address, errors.shipping_address, translations)
  validateFields(values.shipping_address, errors.shipping_address, translations)

  return errors
}

const checkoutValidation = (translations = {}) => (values) => {
  const userErrors = userValidation(values, translations)
  const addressErrors = AddressFieldsValidation(values, translations)
  return { ...userErrors, ...addressErrors }
}

export default checkoutValidation
